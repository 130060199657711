import { render, staticRenderFns } from "./infodialog.html?vue&type=template&id=545cf247&scoped=true&external"
import script from "./infodialog.js?vue&type=script&lang=js&external"
export * from "./infodialog.js?vue&type=script&lang=js&external"
import style0 from "./infodialog.scss?vue&type=style&index=0&id=545cf247&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "545cf247",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardActions,VCardText,VCol,VDialog,VIcon,VRow})
