export default {
  name: "infodialog",
  components: {},
  props: ["reportInfo"],
  data() {
    return {
      infoDialog : false,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    showModal() {
      this.infoDialog = true;
    }
  }
};
