import pageheader from "../../common/pageheader";
import InfoDialog from "@/components/common/infodialog";
import utils from "../../../utils/utils";
import { mapGetters } from "vuex";
// import apiService from "../../../api/api.service.js";
export default {
  name: "supporttoolslist",
  components: { pageheader, InfoDialog },
  props: [],
  data() {
    return {
      reportInfo: {},
      input: {},
      builds:[],
      isBuildData:false,
      buildsList: [
        {
          isCard: true,
          name: "Service List Upload",
          description:
          "<p>Import Instructions.</p><p>Please click the below link to access Service List. </p><p>A new window will open. Select the service file you would like to upload.</p><p>Click the process button.</p>The window below will open. Here you can view and correct errors in the file. The Execute button will insert the services into the DB.</p><p>Note: Please download the below template for reference. Column names should exactly match as given in template to process.</p>",
          icon: "mdi-calendar-clock",
          id: "customer_service_list_upload",
          toolId:"A89B565B-8C01-407C-A2C0-80CAE430077A"
        },
        {
          isCard: true,
          name: "Service List Upload",
          description:
          "<p>Import Instructions.</p><p>Please click the below link to access Service List. </p><p>A new window will open. Select the service file you would like to upload.</p><p>Click the process button.</p>The window below will open. Here you can view and correct errors in the file. The Execute button will insert the services into the DB.</p><p>Note: Please download the below template for reference. Column names should exactly match as given in template to process.</p>",
          icon: "mdi-calendar-clock",
          id: "vendor_service_list_upload",
          toolId:"372AB85A-49D2-4093-9E24-3434351B90FD"
        },
        {
          isCard: true,
          name: "Group Assignment",
          description:
            "<p>Group Assignment tool is used to map the given unit with the group or remove the unit from group based on the uploaded sheet into Trackunit. </p><p><b>Note: </b> Please download the below template for reference. Column names should exactly match as given in Template to process.</p>",
          icon: "mdi-group",
          id: "group_assignment",
          toolId:"775062DD-EF70-4EE8-B079-5B0D95EB9B13"
        },
        {
          isCard: true,
          name: "Hours Update",
          description:
            "<p>Hours Update tool is used to update the Trackunit hours from the uploaded sheet for the given time. </p><p><b>Note: </b> Please download the below template for reference. Column names should exactly match as given in Template to process</p>",
          icon: "mdi-timer",
          id: "hours_update",
          toolId:"52C01730-7DF1-40BF-86CC-14345AE66513"
        },
        {
          isCard: true,
          name: "Metadata Update",
          description:
            "<p>Metadata update tool is used to update the Meta data - Type, Model, Brand, Year, VIN & PO / External Reference details of unit using IRIS. </p><p><b>Note: </b> Please download the below template for reference. Column names should exactly match as given in Template to process.</p>",
          icon: "mdi-timetable",
          id: "metadata_update",
          toolId:"3C134EE5-BA6B-45C7-92F0-A74AB48875AF"
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["roles","userId","customerToken","customerReferenceId","companyName"])
  },
  mounted() {
    // getting input content
    this.input = utils.getInputContent("supportToolsList", this.roles);
    this.getToolsList();
  },
  methods: {
    openModal(build) {
      this.reportInfo = build;
      this.$refs.modal.showModal();
    },
    navigate(build) {
      this.$router.push({
        name: "Support Tools",
        params: { id: build.id }
      });
    },
    getToolsList(){
      this.isBuildData = true;
      try {
        // let response = await apiService.getTools(this.userId);
        let response = this.$store.getters.toolIds;
        if(response.length>0){
        this.isBuildData = false;
        let tempToolsList = response;
        var newToolsList = [];
        for (var k = 0; k < tempToolsList.length; k++) {
          newToolsList.push({
            index: k,
            toolId: tempToolsList[k]
          });
        }
        if(newToolsList && newToolsList.length > 0){
          this.builds = this.buildsList;
        }
        //Filter Tools from Response
        let finalToolsList = [];
        for (var i = 0; i < this.builds.length; i++) {
          for (var j = 0; j < newToolsList.length; j++) {
            if (this.builds[i].toolId == newToolsList[j].toolId) {
              finalToolsList.push(this.builds[i]);
            }
          }
        }
        this.builds = finalToolsList;
      }else{
        this.systemMessage = "Tools for this Company is unavailable";
        this.isBuildData = false;
      }
      } catch (err) {
        this.isBuildData = false;
        console.log("error", err);
      }
    }
  }
};
